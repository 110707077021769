.header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.button {
  font-family: "olivia";
  background-color: black;
  border: none;
  font-size: 200%;
  color: white;
  width: 20vw;
  height: 19vh;
  margin: 10px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

#buttonHome:hover {
  color: magenta;
  font-family: "Dancing Script", cursive;
  font-size: 100px;
  cursor: url('../../Assets/cursors/heartFace.cur'), auto
}

#buttonSkills:hover {
  color: #7DF9FF;
  font-family: "Bowlby One SC", cursive;
  cursor: url('../../Assets/cursors/mess.cur'), auto
}

#buttonProjects:hover {
color: limegreen;
  animation: buttonsSpin infinite 11s cubic-bezier(0.215, 0.610, 0.355, 1);
  font-family: "UnifrakturMaguntia", cursive;
  font-size: 80px;
  cursor: url('../../Assets/cursors/queenCursor.cur'), auto
}

#buttonContact:hover {
  background-image: url("../../Assets/Images/olivia.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  font-family: "Nosifer", cursive;
  font-size: 30px;
  color: red;
  cursor: url('../../Assets/cursors/HumanHeart.cur'), auto
}

@media only screen and (max-width: 768px) {
  .button {
    font-size: 17px;
    width: 20vw;
    height: 10vh;
  }
}

@media only screen and (max-width: 508px) {
  .button {
   font-size: 15px;
  }
}

@media only screen and (max-width: 420px) {
  .button{
   font-size: 12px;
  }
}

