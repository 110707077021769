.page{
    cursor: url('../../Assets/cursors/smileyCursor.cur'), auto;
    padding: 5%;
    justify-content: center;
}

.skillsText{
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    padding-top: 20vh;
    color: #ffffff
}
.olivia{
  font-family: 'olivia';
  display: flex;
  justify-content: center;
 color: #ffffff;
}


.editableText{
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-top: 5%;
    width: 50%;
    padding-left: 25%;
    padding-right: 25%;
    transform:  translate (-50%);
    color: #ffffff;
    font-family: "socfont";
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    max-width: 600px;
    color: red;
}

.skillsBody{
    font-family: 'Roboto Slab';
    display: flex;
    justify-content: center;
    flex-direction: row;
    color: #ffffff;
}

.socfont{
    font-family: "socfont";
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 5%, 10%, 10%, 10%;
    text-align: center;
    color: #ffffff
 }
