.textContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 50%;
  padding-top: 5%;
}

.oliviaHeader {
  font-family: "olivia", sans-serif;
  color: #ffffff;
  font-size: 60px;
  padding: 10px, 60px;
}

#oliviaHeaderHover:hover {
  font-family: "Roboto Slab", serif;
  color: black;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
  background-color: lime;
  border-radius: 20% 50% 30% 40%;
  border: 15px dashed;
  padding: 10px 60px;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.oliviaDescription {
  font-family: "olivia";
  font-size: 38px;
  color: #ffffff;
}

.intro {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  color: #ffffff;
}

.languages {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  color: #ffffff;
}

.madeInReact {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: "Roboto Slab", serif;
  font-size: 10px;
  color: #ffffff;
}

@media only screen and (max-width: 880px){
  .languages {
    font-size: 15px;
    }
} 

@media only screen and (max-width: 768px) {
  .textContainer 
  .oliviaHeader {
    font-size: 40px; 
  }
}

@media only screen and (max-width: 768px) {
  .oliviaDescription{
    font-size: 20px; 
  }
}

@media only screen and (max-width: 660px){
  .languages {
    flex-direction: column;
    align-items: center;
    }
} 

@media only screen and (max-width: 600px) {
  .textContainer 
  .oliviaHeader {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .textContainer 
  .oliviaHeader {
    flex-direction: column;
    align-items: center;
    font-size: 24px;
  }
  .intro{
    font-size:12px;
  }
}

@media only screen and (max-width: 300px) {
  .textContainer 
  .oliviaHeader 
  .oliviaDescription{
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  } 
  .intro{
    font-size:10px;
  }
}